var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-flow":"column","height":"100%"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"on-cancel":_vm.onCancel,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"calendar-switcher-main"},[_c('div',{staticClass:"d-flex calendar-switcher-box"},[_c('div',{staticClass:"rounded arrow",on:{"click":function($event){_vm.date.setDate(_vm.date.getDate() - 1);
          _vm.FetchEvents();}}},[_c('feather-icon',{staticClass:"p-i",attrs:{"icon":"ArrowLeftIcon"}})],1),_c('div',{staticClass:"main-calendar rounded",on:{"click":function($event){return _vm.$bvModal.show('bv-modal-example')}}},[_vm._v(" "+_vm._s(_vm.date.toISOString().split('T')[0])+" "),_c('b-modal',{staticStyle:{"z-index":"10000"},attrs:{"id":"bv-modal-example","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Wybierz datę ")]},proxy:true}])},[_c('div',{staticClass:"d-block text-center"},[_c('h3',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.date),expression:"date"}],attrs:{"first-day-of-week":1,"type":"date"},domProps:{"value":(_vm.date)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.date=$event.target.value},function($event){_vm.date.setDate(_vm.date.getDate());
                  _vm.$bvModal.hide('bv-modal-example');
                  _vm.FetchEvents();}]}})])]),_vm._v(" > ")]),_c('br'),_c('span',{staticStyle:{"font-weight":"500","font-size":"13px"}},[_vm._v(" 8.00 - 19.00 ")])],1),_c('div',{staticClass:"left-arrow rounded arrow",on:{"click":function($event){_vm.date.setDate(_vm.date.getDate() + 1);
          _vm.FetchEvents();}}},[_c('feather-icon',{staticClass:"p-i",attrs:{"icon":"ArrowRightIcon"}})],1)])]),_c('div',{staticClass:"mb-0 full-screen"},[_c('div',[_c('div',{staticClass:"scrollable"},[_c('div',[_c('div',{staticClass:"calendar-employees-bar-container"},_vm._l((_vm.employees),function(employee){return _c('div',{key:'emp' + employee.id,staticClass:"calendar-employee-item"},[_c('div',{staticClass:"d-flex align-items-center; font-family: ProximaNova,system,-apple-system,\\.SFNSText-Regular,San Francisco,Roboto,Segoe UI,Helvetica Neue,Lucida Grande,sans-serif;"},[_c('div',[_c('img',{staticClass:"employee-image",attrs:{"src":employee.image,"alt":""}})]),_c('div',{staticStyle:{"padding-left":"12px"}},[_c('span',{staticClass:"employee-user-name"},[_vm._v(" "+_vm._s(employee.user.name)+" ")]),_c('br'),_c('span',{staticClass:"employee-working-hours"},[_vm._v(" 08.00-16.00 ")])])])])}),0)]),_c('scroll-sync',{staticClass:"timeline-container scrollable",attrs:{"id":"timeline-scrollable-container","proportional":"","vertical":""}},[_c('Timeline',{staticStyle:{"height":"1220px"}})],1),_c('scroll-sync',{staticClass:"scrollable main-scrollable-box",style:({ width: _vm.employees.length * 271 + 'px' }),attrs:{"proportional":"","vertical":""}},[_c('GridLines'),_c('div',{staticClass:"left-pagination",staticStyle:{"display":"inline-block","height":"100%","padding-left":"70px"}}),_vm._l((_vm.employees),function(employee){return _c('span',{key:'emp' + employee.id,staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"calendar-column"},[_c('div',[_vm._l((employee.orders),function(order){return _c('div',{key:'ord' + order.id,staticClass:"grid-item",style:({
                    marginTop: order.margin + 'px',
                    height: order.estaminatedTime + 'px'
                  }),attrs:{"role":"button"}},[_c('div',{staticClass:"order-container"},[_c('div',{staticClass:"order-box",style:({ backgroundColor: order.offer.color })},[_c('div',{staticClass:"ml-1 text-dark order-hours"},[_vm._v(" "+_vm._s(order.start_time.split(':')[0] + ':' + order.start_time.split(':')[1])+" - "+_vm._s(order.end_time.split(':')[0] + ':' + order.end_time.split(':')[1])+" ")]),_c('p',{staticClass:"ml-1 text-dark order-info"},[_c('span',{staticClass:"order-user-name"},[_vm._v(" "+_vm._s(order.user.name)+" ")]),_c('br'),_vm._v(" "+_vm._s(order.offer.title)+" ")])])])])}),_c('time-reservations',{attrs:{"time_reservations":employee.time_reservations},on:{"refetch":_vm.FetchEvents}})],2)])])})],2)],1)]),_c('home-button'),_c('plus-button')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }