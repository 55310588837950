<template>
  <b-dropdown
    size="lg"
    variant="none"
    toggle-class="text-decoration-none"
    no-caret
    class="plus-icon"
  >
    <template #button-content>
      <div
        class="plus-icon text-center"
        role="button"
      >
        <feather-icon
          icon="PlusIcon"
          class="p-i"
        />
      </div>
    </template>
    <b-dropdown-item>
      <router-link
        class="text-dark"
        :to="{name: 'VisitAdd'}"
      >
        Dodaj nową wizytę
      </router-link>
    </b-dropdown-item>

    <b-dropdown-item>
      <router-link
        class="text-dark"
        :to="{name: 'VisitCyclicAdd'}"
      >
        Dodaj cykliczną wizytę
      </router-link>
    </b-dropdown-item>

    <b-dropdown-item>
      <router-link
        class="text-dark"
        :to="{name: 'TimeReservationAdd'}"
      >
        Dodaj rezerwację czasu
      </router-link>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: { BDropdown, BDropdownItem },
}
</script>
<style>
.plus-icon {
  background-color: black;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 30px;
  border-radius: 100vw;
}

.p-i {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
