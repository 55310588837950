<template>
  <div>
    <b-dropdown
      v-for="time_reservation in time_reservations"
      :key="'ord' + time_reservation.id"
      variant="none"
      toggle-class="text-decoration-none"
      no-caret
      class="grid-item"
      :style="{
        marginTop: time_reservation.margin + 'px',
        height: time_reservation.height + 'px'
      }"
      style="
                background-color: #fff;
                background-image: url(https://d10n9ka7jp2kfo.cloudfront.net/pro/109a8827/img/dayoff-pattern.499f3f6b.svg);
                background-size: 18px 18px;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTg5RjkyODc5QTlFMTFFNjlEREE4RkNCOTQ1OTE2RUMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTg5RjkyODg5QTlFMTFFNjlEREE4RkNCOTQ1OTE2RUMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxODlGOTI4NTlBOUUxMUU2OUREQThGQ0I5NDU5MTZFQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxODlGOTI4NjlBOUUxMUU2OUREQThGQ0I5NDU5MTZFQyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmNJISQAAAAuSURBVHjaYnzx4oW4uDgDA8PLly+BJOP////hHCBgQuYAFTIhc6DycA5QDiDAANZtFHn5HqL9AAAAAElFTkSuQmCC)!important;
                border: 1px solid #ebebeb;
            "
    >
      <template #button-content>
        <div class="text-dar">
          <div>
            <div
              class="ml-1"
              style="font-weight: 550;"
            >
              {{ time_reservation.start_time.split(':')[0] + ':' + time_reservation.start_time.split(':')[1] }} - {{ time_reservation.end_time }}
            </div>
            <p class="ml-1">
              <span
                class=""
                style="font-weight: 550;"
              >
                Rezerwacja czasu
              </span>
              <br>
            </p>
          </div>
        </div>
      </template>
      <button
        class="btn btn-danger m-1"
        @click="DeteteTimeReservation( time_reservation.id )"
      >
        Usuń rezerwację
      </button>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown } from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  components: { BDropdown },
  props: ['time_reservations'],
  methods: {
    DeteteTimeReservation(id) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.delete(`${process.env.VUE_APP_API_URL}Time-Reservation/${id}`, config)
        .then(() => {
          swal.fire('Sukces', 'Pomyślnie usunięto rezerwację czasu!', 'success')
          this.$emit('refetch')
        })
    },
  },
}
</script>

<style scoped>
.grid {
  height: 100px;
  margin-left: 10px;
  border: 1px solid rgba(204, 204, 204, 0.344);
  position: relative;
}
.grid-item {
  background-color: rgb(29, 42, 137);
  border-radius: 8px;
  display: inline-block;
  margin-left: 1.20482%;
  width: 97.5904%;
  margin-top: 100px;
  position: absolute;
  border: none;
}
</style>
