<template>
  <div>
    <div
      class="timeline"
      style="font-size: 9px; height: 0px; margin-top: 10px;"
    >
      <span v-for="hour in hours">
        <span class="horizontal-line" />
        <span class="horizontal-line-dashed" />
        <span class="horizontal-line-dashed" />
        <span class="horizontal-line-dashed" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hours: [
        '8.00',
        '9.00',
        '10.00',
        '11.00',
        '12.00',
        '13.00',
        '14.00',
        '15.00',
        '16.00',
        '17.00',
        '18.00',
        '19.00',
        '20.00',
        '21.00',
        '22.00',
      ],
    }
  },
}
</script>

<style scoped>
.horizontal-line {
  width: 100%;
  height: 20px;
  display: block;
  /* horizontal line */
  border-top: 1px solid #d9d9d9;
  border-width: 0.1px
}

.horizontal-line-dashed {
  width: 100%;
  height: 20px;
  display: block;
  /* horizontal line */
  border-top: 1px dashed #d9d9d9;
  border-width: 0.1px
}
</style>
