<!-- eslint-disable radix -->
<template>
  <div
    style="display: flex;  flex-flow: column;
  height: 100%;"
  >

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />

    <!-- <span style="width: 0px; margin-right: 16.5vw"> 23 </span>
    <span style="width: 0px; margin-right: 16.5vw"> 24 </span>
    <span style="width: 0px; margin-right: 16.5vw"> 25 </span>
    <span style="width: 0px; margin-right: 16.5vw"> 26 </span>
    <span style="width: 0px"> 27 </span> -->
    <div class="calendar-switcher-main">
      <div class="d-flex calendar-switcher-box">
        <div
          class="rounded arrow"
          @click="
            date.setDate(date.getDate() - 1);
            FetchEvents();
          "
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="p-i"
          />
        </div>
        <div
          class="main-calendar rounded"
          @click="$bvModal.show('bv-modal-example')"
        >
          {{ date.toISOString().split('T')[0] }}

          <b-modal
            id="bv-modal-example"
            hide-footer
            style="z-index: 10000;"
          >
            <template #modal-title>
              Wybierz datę
            </template>
            <div class="d-block text-center">
              <h3>
                <input
                  :first-day-of-week="1"
                  type="date"
                  v-model="date"
                  @input="
                    date.setDate(date.getDate());
                    $bvModal.hide('bv-modal-example');
                    FetchEvents();
                  "
                />
              </h3>
            </div>
            >
          </b-modal>

          <br>
          <span style="font-weight: 500; font-size: 13px"> 8.00 - 19.00 </span>
        </div>
        <div
          class="left-arrow rounded arrow"
          @click="
            date.setDate(date.getDate() + 1);
            FetchEvents();
          "
        >
          <feather-icon
            icon="ArrowRightIcon"
            class="p-i"
          />
        </div>
      </div>
    </div>
    <div class="mb-0 full-screen">
      <div>
        <div class="scrollable">
          <div>
            <div class="calendar-employees-bar-container">
              <div
                v-for="employee in employees"
                :key="'emp' + employee.id"
                class="calendar-employee-item"
              >
                <div
                  class="d-flex align-items-center; font-family: ProximaNova,system,-apple-system,\.SFNSText-Regular,San Francisco,Roboto,Segoe UI,Helvetica Neue,Lucida Grande,sans-serif;"
                >
                  <div>
                    <img
                      :src="employee.image"
                      class="employee-image"
                      alt=""
                    >
                  </div>

                  <div style="padding-left: 12px">
                    <span class="employee-user-name">
                      {{ employee.user.name }}
                    </span>
                    <br>
                    <span class="employee-working-hours"> 08.00-16.00 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <scroll-sync
            id="timeline-scrollable-container"
            proportional
            vertical
            class="timeline-container scrollable"
          >
            <Timeline style="height: 1220px;" />
          </scroll-sync>

          <scroll-sync
            proportional
            vertical
            :style="{ width: employees.length * 271 + 'px' }"
            class="scrollable main-scrollable-box"
          >

            <GridLines />

            <div
              class="left-pagination"
              style="display: inline-block; height: 100%; padding-left: 70px;"
            />
            <span
              v-for="employee in employees"
              :key="'emp' + employee.id"
              style="margin-top: 10px"
            >
              <div class="calendar-column">
                <div>
                  <div
                    v-for="order in employee.orders"
                    :key="'ord' + order.id"
                    class="grid-item"
                    :style="{
                      marginTop: order.margin + 'px',
                      height: order.estaminatedTime + 'px'
                    }"
                    role="button"
                  >
                    <div class="order-container">
                      <div
                        :style="{ backgroundColor: order.offer.color }"
                        class="order-box"
                      >
                        <div
                          class="ml-1 text-dark order-hours"
                        >
                          {{ order.start_time.split(':')[0] + ':' + order.start_time.split(':')[1] }} - {{ order.end_time.split(':')[0] + ':' + order.end_time.split(':')[1] }}
                        </div>
                        <p class="ml-1 text-dark order-info">
                          <span class="order-user-name">
                            {{ order.user.name }}
                          </span>
                          <br>
                          {{ order.offer.title }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <time-reservations
                    :time_reservations="employee.time_reservations"
                    @refetch="FetchEvents"
                  />
                </div>
              </div>
            </span>
          </scroll-sync>
        </div>
      </div>

      <home-button />
      <plus-button />
    </div>
  </div>
</template>

<script>
import {
  BCalendar,
} from 'bootstrap-vue'
import axios from 'axios'
import swal from 'sweetalert2'
import ScrollSync from 'vue-scroll-sync'
import Loading from 'vue-loading-overlay'
import Timeline from './Timeline.vue'
import GridLines from './GridLines.vue'
import TimeReservations from './TimeReservations.vue'
import PlusButton from './PlusButton.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
import HomeButton from './HomeButton.vue'

export default {
  name: 'Calendar',
  components: {
    BCalendar,
    Timeline,
    GridLines,
    ScrollSync,
    TimeReservations,
    PlusButton,
    Loading,
    HomeButton,
  },
  data() {
    return {
      employees: [],
      report: {},
      date: new Date(),
      startTimeSeconds: 0,
      DateCalendar: null,
      isLoading: true,
      fullPage: true,
    }
  },
  mounted() {
    document.title = 'Grafik pracy biznesu - Pickmode'
    if (this.$route.query.d) {
      this.date = new Date(this.$route.query.d)
    }
    this.FetchEvents()
  },
  methods: {
    FetchEvents() {
      this.isLoading = true
      this.$router.replace({ name: 'Schedule', query: { d: this.date.toISOString() } })

      const config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      const date = this.date.toISOString().split('T')[0]
      // get root api url from env file
      const apiUrl = process.env.VUE_APP_API_URL
      axios
        .get(`${apiUrl}Upcoming-Events/${date}`, config)
        .then(response => {
          this.employees = response.data.employees
          // foreach employee if email is too long, shorten it
          this.employees.forEach(employee => {
            // order orders by start_time desc
            employee.orders
              .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
              .reverse()
            if (employee.user.email.length > 15) {
              employee.user.email = `${employee.user.email.substring(0, 15)}...`
            }
            if (employee.user.name.length > 10) {
              employee.user.name = `${employee.user.name.substring(0, 10)}...`
            }
            const url = process.env.VUE_APP_API_URL.substring(
              0,
              process.env.VUE_APP_API_URL.length - 4,
            )
            employee.image = `${url}uploads/profile_images/${employee.image}`
            employee.orders.forEach(order => {
              if (!order.offer.color) {
                order.offer.color = '#3464eb'
              }
              const startTime = order.start_time.split(':')
              const startTimeSeconds = parseInt(startTime[0]) * 3600
                + parseInt(startTime[1]) * 60
                + parseInt(startTime[2] - 3600 * 8)
              order.margin = startTimeSeconds * 0.02222222222
              // get seconds form of order.offer.estimated_time
              order.estaminatedTime = parseInt(order.offer.estaminated_time) * 3600 * 0.02222222222
              order.end_time = order.start_time.split(':')
              order.end_time[0] = parseInt(order.end_time[0]) + parseInt(order.offer.estaminated_time)
              order.end_time = order.end_time.join(':')
              this.startTimeSeconds = parseInt(startTimeSeconds) + parseInt(order.estaminatedTime)
            })

            // calculate width and margin
            employee.time_reservations.forEach(reservation => {
              const start_time_array = reservation.start_time.split(':')
              reservation.margin = parseInt(start_time_array[0]) * 3600
                + parseInt(start_time_array[1]) * 60
                + parseInt(start_time_array[2] - 3600 * 8)

              reservation.margin *= 0.02222222222

              const end_time_array = reservation.end_time.split(':')
              reservation.height = parseInt(end_time_array[0]) * 3600
                + parseInt(end_time_array[1]) * 60
                + parseInt(start_time_array[2] - 3600 * 8)
              reservation.height = parseInt(reservation.height) * 0.02222222222
              reservation.height -= reservation.margin
            })

            this.startTimeSeconds = 0
            this.isLoading = false
          })
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>

<style scoped>
.employee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-button-containter {
  width: fit-content;
  margin: 0 auto;
}
.eye {
  margin-right: 10px;
  cursor: pointer;
}
.submit {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(204, 204, 204, 0.344);
  border-radius: 5px;
  background: rgba(204, 204, 204, 0.468);
  margin-top: 10px;
}
@media (max-width: 575.98px) {
  .fc {
    width: 100%;
  }
}
.grid-container {
  width: 100%;
}
/*mobile view*/
@media (max-width: 768px) {
  .employee-info {
    display: block;
  }
  .employee-column {
    display: none;
  }
  .grid-container {
    width: 100%;
  }
}

.grid {
  height: 100px;
  margin-left: 10px;
  border: 1px solid rgba(204, 204, 204, 0.344);
  position: relative;
}
.grid-item {
  background-color: rgb(29, 42, 137);
  border-radius: 8px;
  display: inline-block;
  margin-left: 1.20482%;
  width: 97.5904%;
  margin-top: 100px;
  position: absolute;
  border: none;
}
.timeline-ite {
  width: 0;
  padding-right: 181px;
}
.scrollable {
  overflow-x: scroll;
  overflow-y: scro;
  white-space: nowrap;
  /* make scrollbar invisible */
}
.scrollable::-webkit-scrollbar {
  display: none;
}

.plus-icon {
  background-color: black;
  position: fixed;
  bottom: 15px;
  right: 15px;
  border-radius: 10000px;
  width: 10;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 30px;
}

.p-i {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.calendar-employees-bar-container {
  width: fit-content;
  box-shadow: #bababa 1px;
  box-shadow: 0 4px 8px 0 rgba(49, 47, 51, 0.1);
  padding-left: 65px;
  background-color: #ffffff;
  position: relative;
  z-index: 1000;
}

.calendar-employee-item {
  display: inline-block;
  width: 250px;
  padding: 12px 14px;
  max-width: 100%;
  cursor: pointer;
  border-radius: 8px;
}

.calendar-column {
  width: 230px;
  margin-left: 10px;
  display: inline-block;
  background-repeat: repeat;
  position: relative;
  background-position: center;
  height: 1220px;
  background-size: 18px 18px;
}

.timeline-container {
  width: 74px;
  height: 1220px;
  display: inline-block;
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
  overflow-y: scroll;
  position: relative;
  height: 80vh;
  display: inline-block;
  position: absolute;
  z-index: 100;
}

.vertical-line {
  height: 100%;
  width: 0;
  border: 1px black solid;
}

.calendar-switcher-main {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  padding: 0.75rem 1rem;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
}

.calendar-switcher-box {
  margin-left: auto;
  margin-right: auto;
  width: fit-content
}

.arrow {
  padding: 0 7px;
  font-size: 32px;
  border: 1px solid #ebebeb;
  border-color: #c7cbd4;
  background-color: #fff;
  color: #2a2c32;
  box-shadow: 0 2px 4px 0 rgba(49,47,51,.03);
}

.full-screen {
  width: 100vw
}

.employee-image {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  top: 0
}

.employee-user-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5714285714em;
  color: black;
}

.employee-working-hours {
  color: #7f8084;
  font-size: 13px
}

.main-calendar {
  width: 120px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #ebebeb;
  border-color: #c7cbd4;
  text-align: center;
  font-weight: 600;
}

.main-scrollable-box {
  overflow-x: hidden;
  overflow-y: scroll;
  align-items: center;
  border: 0px #bababa solid;
  height: 80vh;
  display: inline-block
}

.order-container {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.5em;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  transition-property: box-shadow, border, background-color;
  pointer-events: auto;
  overflow: hidden;
  cursor: pointer;
  background-color: #ffe7d9;
  color: black;
  font-family: ProximaNova, system, -apple-system,
    \.SFNSText-Regular, San Francisco, Roboto, Segoe UI,
    Helvetica Neue, Lucida Grande, sans-serif;
}

.order-box {
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
}

.order-hours {
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px
}

.ordrer-info {
  margin-top: 6px;
  line-height: 1.375em;
  font-weight: 500;
  font-size: 10px;
}

.order-user-name {
  font-weight: 530;
  font-size: 11px
}
</style>
